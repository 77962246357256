<script setup lang="ts"></script>

<template>
    <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        v-bind="$attrs"
    >
        <path
            d="M20.5652 4.27333H18.9638C19.175 3.93845 19.3144 3.56255 19.3542 3.16499C19.4811 1.89446 18.8132 0.755512 17.676 0.250562C16.6308 -0.213582 15.4561 -0.0238439 14.6103 0.744665L12.5882 2.58049C12.1944 2.14902 11.6283 1.87784 11 1.87784C10.3706 1.87784 9.80356 2.14988 9.40967 2.58269L7.38498 0.74445C6.53777 -0.0240591 5.36361 -0.213066 4.31882 0.25082C3.18196 0.755813 2.51388 1.89515 2.64124 3.16564C2.68107 3.56289 2.82038 3.93862 3.03157 4.27333H1.43477C0.642339 4.27333 0 4.91684 0 5.71062V7.86657C0 8.26343 0.321191 8.58523 0.717405 8.58523H21.2826C21.6788 8.58523 22 8.26347 22 7.86657V5.71062C22 4.91684 21.3576 4.27333 20.5652 4.27333ZM8.84781 4.03379V4.27333H5.47292C4.58123 4.27333 3.87947 3.4406 4.10218 2.51059C4.20027 2.10103 4.49538 1.7519 4.87707 1.57516C5.4012 1.33248 5.9818 1.41038 6.42201 1.80953L8.84841 4.01261C8.84828 4.01972 8.84781 4.02669 8.84781 4.03379ZM17.9306 2.97418C17.8771 3.72112 17.2036 4.27342 16.4561 4.27342H13.1522V4.03388C13.1522 4.0254 13.1516 4.017 13.1515 4.00852C13.7012 3.50939 14.8936 2.42674 15.5403 1.83949C15.9079 1.50577 16.4222 1.34999 16.8976 1.49204C17.5798 1.6959 17.9805 2.27708 17.9306 2.97418Z"
            fill="currentColor"
        />
        <path
            d="M1.43477 10.0225V20.5627C1.43477 21.3565 2.07711 22 2.86953 22H9.56517V10.0225H1.43477Z"
            fill="currentColor"
        />
        <path
            d="M12.4347 10.0225V22H19.1304C19.9228 22 20.5651 21.3565 20.5651 20.5627V10.0225H12.4347Z"
            fill="currentColor"
        />
    </svg>
</template>
